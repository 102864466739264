
@import '@photo-sphere-viewer/markers-plugin/index.css';
@import '@photo-sphere-viewer/virtual-tour-plugin/index.css';
@import "@photo-sphere-viewer/plan-plugin/index.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.custom-tooltip {
  max-width: none;
  width: 300px;
  box-shadow: 0 0 0 3px white;
}

.custom-tooltip .psv-tooltip-content {
  padding: 0;
}

.custom-tooltip img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.custom-tooltip h2,
.custom-tooltip p {
  margin: 1rem;
  text-align: justify;
}